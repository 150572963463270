import React from "react";
import '../../../assets/css/barselectexpertisespractices2V2.css';
import { useTranslation } from "react-i18next";

import { HashLink } from "react-router-hash-link";

export default function BarSelectPracticePP1(){

    const { t } = useTranslation();

    const linkExpertise = document.querySelectorAll('.boxexpertises_container_link1');

    linkExpertise.forEach(link => {
        link.addEventListener('click', function() {
            document.querySelector('.activeexpertise1')?.classList.remove('activeexpertise1');
            this.classList.add('activeexpertise1');
        })
    })

    const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
        const yOffset = offset; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return(

        <div className="barselectexpertises-container1">

                <ul className="boxexpertises-maincontainer1">

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Abonnement/#Abonnement" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="linkDataGovernance">
                                <h4>{t('pages.practices.practicePresse.textlist.textlist1')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="VenteNuméro/#VenteNuméro" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="BIBD">
                                <h4>{t('pages.practices.practicePresse.textlist.textlist2')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="DiffusionNumérique/#DiffusionNumérique" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="BusinessAna" >
                                <h4>{t('pages.practices.practicePresse.textlist.textlist3')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Tirage&Fabrication/#Tirage&Fabrication" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="ERCM">
                                <h4>{t('pages.practices.practicePresse.textlist.textlist4')}</h4>
                            </HashLink>
                        </div>
                    </li>

                    <li className="boxexpertises-container1" id="expertises-button">
                        <div className="boxexpertises-container-hover1">
                            <HashLink smooth to="Publicité/#Publicité" scroll={el => scrollWithOffset(el, -175)} className="boxexpertises_container_link1" id="ERCM">
                                <h4>{t('pages.practices.practicePresse.textlist.textlist5')}</h4>
                            </HashLink>
                        </div>
                    </li>

                </ul>

        </div>

    )

}