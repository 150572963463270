import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarExpertises from "../components/Header/NavbarExpertisesDI";
import Footer from "../components/Footer/Footer";
import BarSelectExpertise1 from "../components/Body/PageExpertise1DI/BarSelectExpertises1";

import DI from "../assets/img/logos/data-intelligence-1_colored.png"

import { Outlet } from "react-router-dom";


export default function PageExpertise1() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PageDI">
            
            <NavbarExpertises/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={DI} className="pageexpertises-headerlogo" alt="icon made by iconixar from flaticon"></img>
                    <h1>{t('pages.expertises.expertise1.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.expertises.expertise1.text')}</p>
                    </div>

                </div>

                <div className="body-pageexpertises2">
                    <BarSelectExpertise1/>
                </div>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

