import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarPractices from "../components/Header/NavbarPracticesPP";
import Footer from "../components/Footer/Footer";
import BarSelectPracticePP1V2 from "../components/Body/PagePractice3PP/BarSelectPracticePP1V2";

import Presse from "../assets/img/logos/journal_colored.png"

import { Outlet } from "react-router-dom";



export default function PagePracticePP() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PagePP">
            
            <NavbarPractices/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">

                    <img src={Presse} className="pageexpertises-headerlogo" alt="icon made by ultimatearm from flaticon"></img>
                    <h1>{t('pages.practices.practicePresse.title')}</h1>


                </div>

                <div className="body-pageexpertises">

                    <div className="cardspagepractices-cardsbody-text-presse">
                        <p>{t('pages.practices.practicePresse.text')}</p>
                    </div>

                    <BarSelectPracticePP1V2/>

                </div>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

