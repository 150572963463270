import React from 'react'
import "../../../assets/css/cardsdomainesV2right.css"

export default function CardsDomainesV2Right(props) {
  return (
    <div className='cards2-domaines-right'>

        <img src={props.img} className="cards2-domaines-img-right" alt="logo domaines"/>

        <div className='cards2-domaines-container-right'>

            <div className='cards2-domaines-container1'>

                <div className='cards2-domaines-title'>

                    {props.title}
 
                </div>

            </div>

            <div className='clear'></div>

            <div className='cards2-domaines-texts'>

                {props.text}

            </div>

        </div>

    </div>
  )
}
