import {React} from "react";
import "../../assets/css/body.css"

import JoinC from "./Join/Join";
import ContactUs from "./Contact/ContactUs";
import ClientsLogosSlider from "./ClientsLogos/ClientsLogosSlider";
import CounterNumber from "./Counter/CounterNumber";
import DomainesV2 from "./Domaines/DomainesV2";


export default function BodyContent(){


    return(

        <div className="body-body">
            <ClientsLogosSlider/>

            <DomainesV2/>
            <CounterNumber/>
            <ContactUs/>
            <JoinC/>

        </div>


    )

}