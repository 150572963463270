import React from "react";
import '.././assets/css/pagecarriere.css';
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer/Footer";
import FormContactRecrut from "../components/Body/Contact/ContactRecrutement";
import NavbarCareer from "../components/Header/NavbarCareer";

//import user from "../assets/img/icons/user.png";
import perso1 from "../assets/img/personnes/maxime_robiquet_CamondoConsulting.jpeg"
import perso2 from "../assets/img/personnes/sarah.jpg";


export default function PageCarriere(){

    const { t } = useTranslation();

    return(

        <div className="pagecareer" id="career">

            <NavbarCareer/>

                <div className="pagecareer-container-body">

                    <div className="header-pagecareer">

                        <h1 className="pagecarreer-title">{t('pages.career.title')}</h1>

                    </div>

                    <div className="body-pagecareer">

                        <div className="body-pagecareer-container1">

                            <h1 className="pagecareer-title">{t('pages.career.container1.title')}</h1>
                            
                            <div className="body-pagecareer-container1-text">

                                <p>{t('pages.career.container1.p1.text1')}<strong>{t('pages.career.container1.p1.text2')}</strong></p>
                                <br/>
                                <p>{t('pages.career.container1.p2.text1')}<strong>{t('pages.career.container1.p2.text2')}</strong>{t('pages.career.container1.p2.text3')}</p>
                                <br/>
                                <p>{t('pages.career.container1.p3.text')}</p>

                            </div>
                            

                        </div>

                        <div className="body-pagecareer-container2">

                            <h1 className="pagecareer-title">{t('pages.career.container2.title')}</h1>

                            <div className="body-pagecareer-container2-text">

                                <p>{t('pages.career.container2.text')}</p>
                                <p>{t('pages.career.container2.text2')}</p>

                                <div className="body-pagecareer-container2-text-job">

                                    <h4>{t('pages.career.container2.p1.title')}</h4>
                                    <br/>
                                    <p>{t('pages.career.container2.p1.text1')}<strong>{t('pages.career.container2.p1.text2')}</strong>{t('pages.career.container2.p1.text3')}<strong>{t('pages.career.container2.p1.text4')}</strong>{t('pages.career.container2.p1.text5')}</p>

                                </div>

                                <div className="body-pagecareer-container2-text-job">

                                    <h4>{t('pages.career.container2.p2.title')}</h4>
                                    <br/>
                                    <p>{t('pages.career.container2.p2.text1')}<strong>{t('pages.career.container2.p2.text2')}</strong>{t('pages.career.container2.p2.text3')}<strong>{t('pages.career.container2.p2.text4')}</strong>{t('pages.career.container2.p2.text5')}</p>

                                </div>

                                <div className="body-pagecareer-container2-text-job">

                                    <h4>{t('pages.career.container2.p3.title')}</h4>
                                    <br/>
                                    <p>{t('pages.career.container2.p3.text1')}<strong>{t('pages.career.container2.p3.text2')}</strong>{t('pages.career.container2.p3.text3')}<strong>{t('pages.career.container2.p3.text4')}</strong>{t('pages.career.container2.p3.text5')}
                                    <strong>{t('pages.career.container2.p3.text6')}</strong>{t('pages.career.container2.p3.text7')}<strong>{t('pages.career.container2.p3.text8')}</strong></p>

                                </div>

                            </div>

                        </div>

                        <div className="body-pagecareer-container3">

                            <FormContactRecrut subject="Formulaire recrutement"/>

                        </div>

                        <div className="body-pagecareer-container4">

                            <div className="body-pagecareer-temoignage1">
                                <div className="body-pagecareer-temoignagesperso1">
                                    <img src={perso1} alt="logo silhouette"/>
                                    <p>{t('pages.career.contact.temoignages.perso1')}</p>
                                </div>
                                
                                <p className="body-pagecareer-temoignage-text">"{t('pages.career.contact.temoignages.tem1')}"</p>
                            </div>

                            <div className="body-pagecareer-temoignage2">
                                <div className="body-pagecareer-temoignagesperso2">
                                    <img src={perso2} alt="logo silhouette"/>
                                    <p>{t('pages.career.contact.temoignages.perso2')}</p>
                                </div>
                                    
                                <p className="body-pagecareer-temoignage-text">"{t('pages.career.contact.temoignages.tem2')}"</p>
                            </div>

                        </div>

                    </div>

                </div>

            <Footer/>
             
        </div>

    )

}