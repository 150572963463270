import React from 'react'
import "../../../assets/css/domainesV2.css"
import "../../../assets/css/domaines.css"
import { useTranslation } from "react-i18next";

import CardsDomainesV2Right from './CardsDomainesV2Right';
import CardsDomainesV2Left from './CardsDomainesV2Left';

import background1 from "../../../assets/img/background/finances-risques2.jpg";
import background2 from "../../../assets/img/background/marketing.jpg";
import background3 from "../../../assets/img/background/achats.jpg";
import background4 from "../../../assets/img/background/gens-affaires-se-serrant-main.jpg";

export default function DomainesV2() {

    const { t } = useTranslation();

    return(
        <div className='domaines-bodyV2' id='domaines'>

            <h2 id="domaines-titre">
                {t('body.domaines.title')}
            </h2>

            <div className="cards-domaines-maincontainer-v2">

                
                <CardsDomainesV2Right
                    title={t('body.domaines.cards.FinancesRisques.title')}
                    img={background1}
                    text={t('body.domaines.cards.FinancesRisques.text')}
                />

                <CardsDomainesV2Left
                    title={t('body.domaines.cards.Marketing.title')}
                    img={background2}
                    text={t('body.domaines.cards.Marketing.text')}
                />

                <CardsDomainesV2Right
                    title={t('body.domaines.cards.Achats.title')}
                    img={background3}
                    text={t('body.domaines.cards.Achats.text')}
                />

                <CardsDomainesV2Left
                    title={t('body.domaines.cards.RH.title')}
                    img={background4}
                    text={t('body.domaines.cards.RH.text')}
                />

            </div>

            <div className='clear'></div>
            <div className='clear'></div>

        </div>
    )
}