import React from "react";
import '.././assets/css/pageexpertisespractices.css';
import { useTranslation } from "react-i18next";
import NavbarExpertisesDE from "../components/Header/NavbarExpertisesDE";
import Footer from "../components/Footer/Footer";

import DE from "../assets/img/logos/digital-experience-1_colored.png"

import { Outlet } from "react-router-dom";

export default function PageExpertise2() {

    const { t } = useTranslation();

    return (

        <div className="maincontainer-pageexpertises" id="PageDI">
            
            <NavbarExpertisesDE/>
            
            <div className="container-pageexpertises">

                <div className="header-pageexpertises">
                    <img src={DE} className="pageexpertises-logo" alt="icon made by prosymbols-premium from flaticon"></img>
                    <h1>{t('pages.expertises.expertise2.title')}</h1>

                    <div className="header-pageexpertises-text">
                        <p>{t('pages.expertises.expertise2.text')}</p>
                    </div>
                    
                </div>


            </div>

            <Outlet/>

            <Footer/>

        </div>
        

    );
}

